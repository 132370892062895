import React, { useEffect, useState } from "react";
import "./TemplateSelector.scss";
import clsx from "clsx";
import { GetLoginedUser, UserLogined } from "../utils";
import { Card, List } from "antd";
import Meta from "antd/es/card/Meta";
import { ToolButton } from "./ToolButton";
import { IconCloseWin, SearchIcon, TemplateIcon } from "./icons";
import { AppState } from "../types";
import { Modal } from "./Modal";
import { loadFromBlob } from "../data";
import { createFile, getMimeType } from "../data/blob";
import { inflate } from "pako";
import { NavLink, Route } from "react-router-dom";
import { checkLangKey, t } from "../i18n";
import { split } from "lodash";

export const TemplateSelector: React.FC<{
  appState: AppState;
  addFileToCanvas: (file: File) => Promise<void>;
}> = ({ appState, addFileToCanvas }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [keywords, setKeyWords] = useState<string>("");

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode == 13) {
      setKeyWords(event.target.value);
    }
  };

  const handelCardClick = async (tpl: string) => {
    setOpenModal(false);
    try {
      const request = await fetch(`/api/v1/download/templates?path=${tpl}`, {
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
        },
      });
      const data = await request.blob();
      await addFileToCanvas(createFile(data, getMimeType(data), "a"));
    } catch (error: any) {}
  };

  return (
    <>
      <ToolButton
        className={""}
        key=""
        icon={TemplateIcon}
        type="icon"
        name="ViewBackgroundSelect"
        id="ViewBackgroundSelect"
        //checked={appState.viewBackgroupStyle === selected.value}
        aria-label={"ViewBackgroundSelect"}
        //onChange={() => setActive(!isActive)}
        title={"ViewBackgroundSelect"}
        onClick={() => {
          setOpenModal(true);
        }}
      />
      {openModal && (
        <Modal
          labelledBy="dialog-title"
          maxWidth={960}
          onCloseRequest={() => {
            setOpenModal(false);
          }}
          closeOnClickOutside={true}
        >
          <div className="template-modal">
            <div></div>
            <div>
              <a
                className="modal-close"
                href={undefined}
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                {IconCloseWin}
              </a>
              <div className="search">
                {SearchIcon}
                <input
                  placeholder={t("home_welcome.search_template_by_name")}
                  onKeyDown={handleSearch}
                ></input>
              </div>
            </div>
            <Templates
              keywords={keywords}
              listColumn={3}
              handleCardClick={(item) => handelCardClick(item.source)}
              listLimit={6}
            ></Templates>
          </div>
        </Modal>
      )}
    </>
  );
};

export const getCatName = (item: any): string => {
  let key = item.nameKey.replace("ctg_", "");
  if (!item.nameKey.endsWith("_")) {
    key = key.concat("_");
  }
  const cat_key = `data_record.template.${key}`;
  if (checkLangKey(cat_key)) {
    const v = t(cat_key);
    if (v != "") {
      return v;
    }
    return item.name;
  }
  return item.name;
};

export const Templates: React.FC<{
  keywords: string;
  listColumn?: number;
  listLimit?: number;
  handleCardClick: (item: any) => void;
  showMenu?: boolean;
}> = ({
  keywords,
  listColumn = 4,
  listLimit = 8,
  handleCardClick,
  showMenu = true,
}) => {
  const [title, setTitle] = useState(t("labels.all_templates"));
  const [templatesTotal, setTemplateTotal] = useState(0);
  const [categoriesTotal, setCategoriesTotal] = useState(0);
  const [templates, setTemplates] = useState<any[]>();
  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState("0");
  const [categories, setCategories] = useState<any[]>();
  const host = "";
  const user = GetLoginedUser();
  const isLogined = UserLogined();

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${host}/api/v1/categories/${categoryId}/templates?page=${page}&limit=${listLimit}&key=${keywords}`,
        {
          headers: {
            Authorization: `Bearer ${user?.authorization}`,
          },
        },
      );
      const templates = await response.json();
      setTemplates(templates.data.templates);
    })();
  }, [page, categoryId, keywords, listLimit]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${host}/api/v1/stats/categories/${categoryId}/templates`,
        {
          headers: {
            Authorization: `Bearer ${user?.authorization}`,
          },
        },
      );
      setTemplateTotal((await response.json()).data);
    })();
  }, [categoryId, keywords]);

  useEffect(() => {
    fetch(`${host}/api/v1/categories?app=1`, {
      headers: {
        Authorization: `Bearer ${user?.authorization}`,
      },
    }).then(async (response) => {
      const categories = (await response.json()).data;
      setCategories(categories);
      setCategoriesTotal(categories.length);
    });
  }, []);

  if (!isLogined) {
    location.href = "/";
    return null;
  }

  return (
    <>
      {showMenu && (
        <div className="menu">
          <ul>
            <li>
              <NavLink to="/dashboard">{t("home_welcome.dashboard")}</NavLink>
            </li>

            <li>
              <NavLink to="/templates-categories">
                {t("home_welcome.categories")}
              </NavLink>
            </li>
          </ul>
        </div>
      )}
      <div className="content">
        <h2>{t("home_welcome.categories")}</h2>
        <div className="list">
          <List
            grid={{ xs: 1, sm: 2, md: 2, column: listColumn }}
            pagination={
              categoriesTotal > listLimit
                ? {
                    onChange: (page) => {
                      setPage(page);
                    },
                    pageSize: listLimit,
                    total: categoriesTotal,
                    current: page,
                  }
                : false
            }
            dataSource={categories}
            renderItem={(item) => (
              <Card
                hoverable
                key={item.id}
                style={{ margin: "10px" }}
                cover={
                  <div className="card-cover">
                    <img
                      alt={item.nameKey}
                      src={`/api/v1/download/category-images?path=source/${item.nameKey}.jpg`}
                    />
                  </div>
                }
                onClick={() => handleCardClick(item)}
              >
                <Meta title={getCatName(item)} />
              </Card>
            )}
          />
        </div>
      </div>
    </>
  );
};
